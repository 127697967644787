import React, { useState } from 'react';
import './App.css';
import SearchBar from './SearchBar';
import EmailList from './EmailList';

function App() {
  const [emails] = useState([
    { id: 1, sender: 'alice@example.com', subject: 'Meeting Reminder', date: '2023-04-12', snippet: 'Just a reminder about our meeting tomorrow at 10AM.' },
    { id: 2, sender: 'bob@example.com', subject: 'Project Proposal', date: '2023-04-11', snippet: 'Please find attached the proposal for the new project.' },
    { id: 3, sender: 'carol@example.com', subject: 'Re: Lunch Plans', date: '2023-04-10', snippet: 'Sounds good! See you there at noon.' }
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmailId, setSelectedEmailId] = useState(null);

  const filteredEmails = emails.filter(email =>
    email.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
    email.sender.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="App">
      <SearchBar onSearch={setSearchTerm} />
      <EmailList emails={filteredEmails} selectedEmailId={selectedEmailId} onEmailSelect={setSelectedEmailId} />
    </div>
  );
}

export default App;
