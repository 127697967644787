function EmailList({ emails, selectedEmailId, onEmailSelect }) {
  return (
    <div>
      {emails.map(email => (
        <div
          key={email.id}
          className={`email-item ${selectedEmailId === email.id ? 'selected' : ''}`}
          onClick={() => onEmailSelect(email.id)}
        >
          <div className="email-sender">{email.sender}</div>
          <div className="email-subject">{email.subject}</div>
          <div className="email-date">{email.date}</div>
          <div className="email-snippet">{email.snippet}</div>
        </div>
      ))}
    </div>
  );
}

export default EmailList;